<style lang="sass">
    @import '../node_modules/typeface-roboto/index.css'
</style>

<template>
  <transition mode="out-in">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: 'BounCA',
};
</script>
