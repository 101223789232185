<template>
  <div id="lost-view">
    <h1>Page Not Found</h1>
    <router-link to="/">Home.</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
